import {
  PROPERTY_ARUBA,
  PROPERTY_ATMORE,
  PROPERTY_ATMORE_VIP,
  PROPERTY_BETHLEHEM,
  PROPERTY_CORPORATE,
  PROPERTY_CURACAO,
  PROPERTY_MONTGOMERY,
  PROPERTY_MONTGOMERY_VIP,
  PROPERTY_WETUMPKA,
  PROPERTY_WETUMPKA_VIP,
} from 'server/src/utils/constants';

export const SORT_TYPE_ID = 'id';
export const SORT_TYPE_OPS_ID = 'opsId';
export const SORT_TYPE_PROPERTY = 'property';
export const SORT_TYPE_STATUS = 'status';
export const SORT_TYPE_CREATION = 'createdAt';

export const DASHBOARD_ROUTE = '/';
export const CALENDAR_ROUTE = '/calendar';
export const ANALYSIS_ROUTE = '/analysis';
export const LOGIN_ROUTE = '/login';
export const ADD_NEW_OPS_GUIDE_ROUTE = '/add-new-ops-guide';
export const OPS_GUIDE_ROUTE = '/ops-guide';
export const ADD_NEW_PROMOTION_ROUTE = `${OPS_GUIDE_ROUTE}/add-new-promotion`;
export const COPY_EXISTING_PROMOTION_ROUTE = `${OPS_GUIDE_ROUTE}/copy-existing-promotion`;
export const FORM_APPROVAL_ROUTE = `/my-projects`;
export const EMAIL_LIST_ROUTE = '/email-list';
export const ROUTE_LOGIN = '/login';
export const ROUTE_OKTA_LOGIN_CALLBACK = '/login/callback';
export const ROUTE_ID_PROMO_TEMPLATE = 'promo-template';
export const ROUTE_ID_PROMO_ASSETS = 'assets';
export const DOWNLOAD_PDF_ROUTE = '/download';
export const PRIZE_CODE_REQUEST_ROUTE = '/prize-code-request';
export const MY_PROJECTS_ROUTE = '/my-projects';

export const STATUS_REVIEW_PROGRESS = 'progress';
export const STATUS_REVIEW_REVIEW = 'review';
export const STATUS_REVIEW_APPROVALS = 'approvals';
export const STATUS_VALUE_SUCCESS = 'success';
export const STATUS_VALUE_WARNING = 'warning';
export const STATUS_VALUE_ERROR = 'error';

export const CALENDAR_ASSET = 'calendarAssets';
export const SUPPORTING_ASSET = 'supportingAssets';
export const INVITE_LIST_ASSET = 'inviteLists';

// Alert types
export const ALERT_ERROR = 'error';
export const ALERT_INFO = 'info';
export const ALERT_SUCCESS = 'success';
export const ALERT_WARNING = 'warning';

// Misc Constants for multi-file use
// Dropdown
export const DROPDOWN_INLINE_COMPONENT_TYPE = 'inline';
export const DROPDOWN_NO_DROP_INPUT_TYPE = 'text';

// Email List Parameters
export const EMAIL_ROLES_LABELS = {
  assistantPropertyManager: 'Assistant Property Manager',
  propertyManager: 'Property Manager',
  promotionsCoordinator: 'Promotions Coordinator',
  promotionsManager: 'Promotions Manager',
  promotionsSupervisor: 'Promotions Supervisor',
  corporateDirectorOfPromotions: 'Corporate Director of Promotions',
  casinoMarketingSupervisor: 'Casino Marketing Supervisor',
  casinoMarketingRelationshipDirector: 'Casino Marketing Relationship Director',
  legal: 'Legal',
  chiefComplianceOfficer: 'Chief Compliance Officer',
  tcg: 'TCG',
  promotionApproverRoundOne: 'Round 1 Promotion Approval',
  promotionApproverRoundTwo: 'Round 2 Promotion Approval',
};

export const PROPERTY_CODE_AS_PROPERTY = {
  [PROPERTY_ATMORE]: 'Atmore',
  [PROPERTY_BETHLEHEM]: 'Bethlehem',
  [PROPERTY_MONTGOMERY]: 'Montgomery',
  [PROPERTY_WETUMPKA]: 'Wetumpka',
  [PROPERTY_CORPORATE]: 'Corporate',
  [PROPERTY_CURACAO]: 'Curacao',
  [PROPERTY_ARUBA]: 'Aruba',
  [PROPERTY_ATMORE_VIP]: 'Aruba VIP',
  [PROPERTY_MONTGOMERY_VIP]: 'Montgomery VIP',
  [PROPERTY_WETUMPKA_VIP]: 'Wetumpka VIP',
};
