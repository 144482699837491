import { gql } from '@apollo/client';

export const emailListFragment = gql`
  fragment EmailListTemplate on EmailList {
    id
    property
    propertyManager
    assistantPropertyManager
    promotionsCoordinator
    promotionsManager
    promotionsSupervisor
    corporateDirectorOfPromotions
    casinoMarketingSupervisor
    casinoMarketingRelationshipDirector
    chiefComplianceOfficer
    legal
    tcg
    promotionApproverRoundOne
    promotionApproverRoundTwo
  }
`;
